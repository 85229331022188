import React, { useEffect, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useConfig } from '../../providers/ConfigProvider';
import { useBreadcrumbs, useFindRouteComponent } from '../../providers/RoutesProvider';
import { BemWithSpecifiContext } from '../../utils/bem';
import { absoluteURL } from '../../utils/helpers';
import { Dropdown } from '../Dropdown/Dropdown';
import { Menu } from '../Menu/Menu';
import './Breadcrumbs.styl';
import { getRegion } from '../../utils/cookie'
import { GlobalContext } from '../../store';

const {Block, Elem} = BemWithSpecifiContext();

export const Breadcrumbs = () => {
  const [isAdmin,setAdmin] = useState(true)
  const { updateAdmin } = useContext(GlobalContext)
  const config = useConfig();
  const reactBreadcrumbs = useBreadcrumbs();
  const findComponent = useFindRouteComponent();
  const [breadcrumbs, setBreadcrumbs] = useState(reactBreadcrumbs);

  useEffect(() => {
    if (reactBreadcrumbs.length) {
      setBreadcrumbs(reactBreadcrumbs);
    } else if (config.breadcrumbs) {
      setBreadcrumbs(config.breadcrumbs);
    }
  }, [reactBreadcrumbs, config]);

  useEffect(async () => {
    const isAdminRes = await fetch(`/${getRegion()}/dataLabel/api/isadmin`)
    const isAdmin = await isAdminRes.json() 
    setAdmin(isAdmin);
    updateAdmin(isAdmin);
  },[])

  return (
    <Block name="breadcrumbs">
      <Elem tag="ul" name="list">
        {breadcrumbs.map((item, index, list) => {
          const isLastItem = index === list.length - 1;

          const key = `item-${index}-${item.title}`;

          const href = item.href ?? item.path;

          const isInternal = findComponent(href) !== null;

          const title = (
            <Elem tag="span" name="label" mod={{faded: index === item.length - 1}}>
              {item.title}
            </Elem>
          );

          const dropdownSubmenu = item.submenu ? (
            <Dropdown>
              <Menu>
                {item.submenu.map((sub, index) => {
                  return <Menu.Item
                    key={`${index}-${item.title}`}
                    label={sub.title}
                    icon={sub.icon}
                    href={sub.href ?? sub.path}
                    active={sub.active}
                  />;
                })}
              </Menu>
            </Dropdown>
          ) : null;

          return item.onClick ? (
            <Elem key={key} tag="li" name="item" mod={{last: isLastItem}}>
              <span onClick={isAdmin ? item.onClick : ()=> {}}>{title}</span>
              {/* <span >{title}</span> */}
            </Elem>
          ) : dropdownSubmenu ? (
            <Elem key={key} tag="li" component={Dropdown.Trigger} name="item" mod={{last: isLastItem}} content={dropdownSubmenu}>
              <span>{title}</span>
            </Elem>
          ) : (href && !isLastItem) ? (
            <Elem key={key} tag="li" name="item" mod={{last: isLastItem}}>
              {isInternal ? (
                isAdmin ?<NavLink to={href} data-external={true}>{title}</NavLink>:<span>{title}</span>
              ) : (
                <a href={absoluteURL(href)}>{title}</a>
              )}
            </Elem>
          ) : (
            <Elem key={key} tag="li" name="item" mod={{last: isLastItem}}>
              {title}
            </Elem>
          );
        })}
      </Elem>
    </Block>
  );
};
