import React, { useState,Fragment, useCallback } from 'react'

import { Dropdown } from 'antd'
import { debounce, get } from 'lodash'
import { getRegion, getProjectId } from '../../utils/cookie'

const FILE_TYPE = {
  DIR: 'DIR',
  FILE:'FILE'
}

const getPath = (query,set,isFilter=false) => {
  if (!query) {
    set([])
    return
  }

  fetch(`/${getRegion()}/api/v2/idp-note-rs/workspace/dir/pre-search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      projectId: Number(getProjectId()),
      keyword: query
    })
  })
  .then((res) => res.json())
    .then(({ data }) => {
      const newData = data?.map(({ fileName, fileType, browserPath }) => ({ label: browserPath, key: browserPath, filename:fileName, filetype:fileType}))||[]
      set(isFilter ? newData.filter(item => item.filetype.toLocaleUpperCase() === FILE_TYPE.DIR) : newData)
  })
}

const onClick  = (key,setVal,setList,isDirFilter=false) => {
  setVal(key)
  getPath(key,setList,isDirFilter)
}

const PathDropdown = ({ value = '', setVal, isDirFilter=false,placeholder='Workspace Path' }) => {
  const [pathList,setPathList] = useState([])
 
  const getData = useCallback(debounce((e) => {
    getPath(e, setPathList,isDirFilter)
  }, 500), [])
  
  return <Fragment>
    <Dropdown overlayStyle={{maxHeight:350,overflow:'auto'}} menu={{ items: pathList, onClick:({key})=>onClick(key,setVal,setPathList,isDirFilter) }}>
      <input value={value}
        onFocus={() => {
          getData(value)
        }}
        onChange={(e) => {
          setVal(e.target.value)
          getData(e.target.value)
        }} type="text" placeholder={placeholder}
      />
    </Dropdown>
  </Fragment>
}

export default PathDropdown