function getCookie(name) {
  let cookies = document.cookie;
  let list = cookies.split("; ");     // 解析出名/值对列表
  for (let i = 0; i < list.length; i++) {
    let arr = list[i].split("=");   // 解析出名和值
    if (arr[0] == name)
      return decodeURIComponent(arr[1]);   // 对cookie值解码
  }
  return "";
}

export function getRegion() {
  return getCookie('region');
}

export function getTeamId() {
  return getCookie('teamId');
}

export function getProjectId() {
  return getCookie('projectId');
}