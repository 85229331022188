import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import { LsCross } from "../../../assets/icons";
import { Button, Userpic } from "../../../components";
import { Block, Elem } from "../../../utils/bem";
import "./SelectedUser.styl";
import { useContext, useState } from "react";
import { getRegion } from '../../../utils/cookie'
import { GlobalContext } from '../../../store';

const UserProjectsLinks = ({ projects, isAdmin }) => {
  
  return (
    <Elem name="links-list">
      {projects.map((project) => (
        <Elem
          tag={NavLink}
          name="project-link"
          key={`project-${project.id}`}
          to={`/projects/${project.id}${isAdmin ? '' : '/data?labeling=1'}`}
          data-external
        >
          {project.title}
        </Elem>
      ))}
    </Elem>
  );
};

export const SelectedUser = ({ user, onClose }) => {
  const fullName = [user.first_name, user.last_name].filter(n => !!n).join(" ").trim();
  // const [isAdmin,setAdmin] = useState(true)
  const { isAdmin } = useContext(GlobalContext);

  // useEffect(async () => {
  //   const isAdminRes = await fetch(`/${getRegion()}/dataLabel/api/isadmin`)
  //   const isAdmin = await isAdminRes.json() 
  //   setAdmin(isAdmin)
  //   // setAdmin(false)
  // },[])

  return (
    <Block name="user-info">
      <Elem name="close" tag={Button} type="link" onClick={onClose}><LsCross/></Elem>

      <Elem name="header">
        <Userpic
          user={user}
          style={{width: 64, height: 64, fontSize: 28}}
        />

        {fullName && (
          <Elem name="full-name">{fullName}</Elem>
        )}

        <Elem tag="p" name="email">{user.email}</Elem>
      </Elem>

      {user.phone && (
        <Elem name="section">
          <a href={`tel:${user.phone}`}>{user.phone}</a>
        </Elem>
      )}

      {!!user.created_projects.length && (
        <Elem name="section">
          <Elem name="section-title">Created Projects</Elem>

          <UserProjectsLinks projects={user.created_projects} isAdmin={isAdmin} />
        </Elem>
      )}

      {!!user.contributed_to_projects.length && (
        <Elem name="section">
          <Elem name="section-title">Contributed to</Elem>

          <UserProjectsLinks projects={user.contributed_to_projects} isAdmin={isAdmin} />
        </Elem>
      )}

      <Elem tag="p" name="last-active">
        Last activity on: {format(new Date(user.last_activity), 'dd MMM yyyy, KK:mm a')}
      </Elem>
    </Block>
  );
};
