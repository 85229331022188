import cookie from 'react-cookies';
import zhCN from './zh-CN.json';
import enUS from './en-US.json';

const getLang = () => {
  let lang = cookie.load('locale') || navigator.language || navigator.browserLanguage;
  if (undefined === lang || '' === lang) {
    lang = 'zhCN';
  } else {
    if (lang.indexOf('zh') !== -1) {
      lang = 'zhCN';
    } else {
      lang = 'enUS';
    }
  }
  return lang;
}

let data = zhCN;
const setData = () => {
  const lang = getLang();
  switch (lang) {
    case 'zhCN':
      data = zhCN;
      break;
    default:
      data = enUS;
      break;
  }
}
setData();

const get = (key) => {
  const value = data[key];
  return value ? value : '';
}

const translate = (keys) => {
  let values = [];
  for (const key of keys) {
    const value = data[key];
    values.push(value ? value : key);
  }
  return values;
}

export default { get, translate };
