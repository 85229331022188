import { createContext } from 'react';

export const FormContext = createContext();
FormContext.displayName = 'FormContext';

export const FormValidationContext = createContext();
FormValidationContext.displayName = 'FormValidationContext';

export const FormSubmissionContext = createContext();
FormSubmissionContext.displayName = 'FormSubmissionContext';

export const FormStateContext = createContext();
FormStateContext.displayName = 'FormStateContext';

export const FormResponseContext = createContext();
FormResponseContext.displayName = 'FormResponseContext';
