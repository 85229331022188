import React from 'react';
import { Columns } from '../../../components/Columns/Columns';
import { Description } from '../../../components/Description/Description';
import { Block, cn } from '../../../utils/bem';
import { StorageSet } from './StorageSet';
import './StorageSettings.styl';
import intl from 'react-intl-universal'

export const StorageSettings = () => {
  const rootClass = cn("storage-settings");

  return (
    <Block name="storage-settings">
      <Description style={{marginTop: 0}}>
        {intl.get('Use_Cloud_Storage')}
      </Description>

      <Columns count={2} gap="40px" size="320px" className={rootClass}>
        <StorageSet
          title={intl.get('Source_Cloud_Storage')}
          buttonLabel={intl.get('Add_Source_Storage')}
          rootClass={rootClass}
        />

        <StorageSet
          title={intl.get('Target_Cloud_Storage')}
          target="export"
          buttonLabel={intl.get('Add_Target_Storage')}
          rootClass={rootClass}
        />
      </Columns>
    </Block>
  );
};

StorageSettings.title = ()=>intl.get('Cloud_Storage');
StorageSettings.path = "/storage";
