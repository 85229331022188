/* global Sentry */

import { createBrowserHistory } from 'history';
import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import { initSentry } from "../config/Sentry";
import { ApiProvider } from '../providers/ApiProvider';
import { AppStoreProvider } from '../providers/AppStoreProvider';
import { ConfigProvider } from '../providers/ConfigProvider';
import { LibraryProvider } from '../providers/LibraryProvider';
import { MultiProvider } from '../providers/MultiProvider';
import { ProjectProvider } from '../providers/ProjectProvider';
import { RoutesProvider } from '../providers/RoutesProvider';
import './App.styl';
import { AsyncPage } from './AsyncPage/AsyncPage';
import ErrorBoundary from './ErrorBoundary';
import { RootPage } from './RootPage';
import { GlobalContext } from '../store'
import { getRegion } from '../utils/cookie'
import './app.css'



const baseURL = new URL(APP_SETTINGS.hostname || location.origin);

const browserHistory = createBrowserHistory({
    basename: baseURL.pathname || "/",
});

window.LSH = browserHistory;

// initSentry(browserHistory);

const App = ({ content }) => {
    const libraries = {
        lsf: {
            scriptSrc: window.EDITOR_JS,
            cssSrc: window.EDITOR_CSS,
            checkAvailability: () => !!window.LabelStudio,
        },
        dm: {
            scriptSrc: window.DM_JS,
            cssSrc: window.DM_CSS,
            checkAvailability: () => !!window.DataManager,
        },
    };

    const [labelSouce, setLabelSouce] = useState('dataLabel');
    const [isAdmin, setAdmin] = useState(true)
    const [loading, SetLoading] = useState(true);
    const [pageSource, setPageSource] = useState();

    const getAdminData = async (callback) => {
        const isAdminRes = await fetch(`/${getRegion()}/dataLabel/api/isadmin`)
        const isAdmin = await isAdminRes.json();
        callback(isAdmin);
    }

    useEffect(() => {
        const loadDom = document.querySelector('#loading-page-gif');
        if (loadDom) {
            loadDom.style.display = 'none';
        }
    }, []);

    useEffect(() => {
        const qs = new URLSearchParams(window.location.search)
        const labelSouce = qs.get("labelSouce");
        const projectId = qs.get("projectId");
        projectId && setPageSource(projectId);
        labelSouce && setLabelSouce(labelSouce);
        SetLoading(true);
        setTimeout(() => {
            idpLog(() => {
                getAdminData((isAdmin) => {
                    SetLoading(false);
                    setAdmin(isAdmin);
                });
            })
        });
    }, []);

    const idpLog = (callback) => {
        console.log('------执行了')
        fetch(`/${getRegion()}/dataLabel/api/idp`).then((res) => {
            // 国际化
            callback();
        }).catch((err) => {
            console.log(err, 'error')
        });
    }

    const updateAdmin = (isAdmin) => {
        setAdmin(isAdmin);
    }


    return (
        <ErrorBoundary>
            {loading ? <div className='loading'></div> : <GlobalContext.Provider value={{ labelSouce, isAdmin, updateAdmin, pageSource, setPageSource }}>
                <Router history={browserHistory}>
                    <MultiProvider providers={[
                        <AppStoreProvider key="app-store" />,
                        <ApiProvider key="api" />,
                        <ConfigProvider key="config" />,
                        <LibraryProvider key="lsf" libraries={libraries} />,
                        <RoutesProvider key="rotes" />,
                        <ProjectProvider key="project" />,
                    ]}>
                        <AsyncPage>
                            <RootPage content={content} />
                        </AsyncPage>
                    </MultiProvider>
                </Router>
            </GlobalContext.Provider>}
        </ErrorBoundary>
    );
};

export default App
